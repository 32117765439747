import { css } from '@linaria/core';
import { REM13, REM15, REM18, REM22, REM24, REM28, REM20, ROOT_FONT_SIZE, ROOT_MOBILE_FONT_SIZE, ROOT_LINE_HEIGHT } from 'src/theme/Types';
import { HairlineVarName, Colors, MainContentWidth, NavBarWidth, SmallMobileBreakpoint, FontWeights, SideBarWidth, Theme } from 'src/theme/constants';
import { followDeepLinks } from 'src/scopes/deeplinks/deeplinks';
import Head from 'next/head';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AuthTokensKey, JWTValues } from 'src/scopes/auth/constants';
import config, { Env } from 'src/config';
import { useEffect } from 'react';
import { Router } from 'next/router';
import jwt_decode from 'jwt-decode';
import 'src/polyfills/requestIdleCallback';
import 'src/polyfills/allSettled.polyfill';
import 'src/scopes/analytics/google-scripts';
import { captureException } from 'src/dev/sentry';
import { ldAnonUser } from 'src/scopes/analytics/constants';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { identifyAnalytics, setAnalyticsLoggedOut } from 'src/scopes/analytics/mixpanel';
import { isServer } from 'src/utils/isServer';
import ScreenAnalytics from 'src/scopes/analytics/ScreenAnalytics';
import { OxcartPage } from 'src/utils/oxcart';
import { scrollbar } from 'src/theme/scrollbar';
import SeoTags, { getBaseSeoProps } from 'src/components/SeoTags';
import { darkColors, darkWeights, themeDark } from 'src/theme/themes/dark';
import { lightColors, lightWeights, themeLight } from 'src/theme/themes/light';
type Props = {
  Component: OxcartPage<unknown>;
  pageProps: any;
  router: Router;
}; // @NOTE: Setting initial theme class as quickly as possible to minimize color flashing
// It can still happens depending on users machine, but only when the user has a different theme as his OS

function loadTheme() {
  if (isServer) return;
  const theme = localStorage.getItem('cs:theme');
  const body = document.body;
  if (!body) return;
  body.style.setProperty('visibility', 'unset');
  if (!theme) return;
  body?.classList.toggle(themeDark, theme === Theme.Dark);
  body?.classList.toggle(themeLight, theme !== Theme.Dark);
} // Check for callback urls before loading the app


if (typeof window !== 'undefined' && typeof location !== 'undefined') {
  followDeepLinks();
  loadTheme();
} // Canny is a community tool used to gather feedback


function loadCanny() {
  // @ts-ignore
  // prettier-ignore
  !function (w, d, i, s) {
    function l() {
      if (!d.getElementById(i)) {
        let f = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
        e.type = "text/javascript", e.async = !0, e.src = "https://canny.io/sdk.js", f.parentNode.insertBefore(e, f);
      }
    }

    if ("function" != typeof w.Canny) {
      let c = function (...args) {
        c.q.push(args);
      };

      c.q = [], w.Canny = c, "complete" === d.readyState ? l() : w.attachEvent ? w.attachEvent("onload", l) : w.addEventListener("load", l, !1);
    }
  }(window, document, "canny-jssdk", "script");
}

const MyApp = ({
  Component,
  pageProps,
  router
}: Props) => {
  const {
    getLayout
  } = Component;
  const origin = pageProps.origin ? pageProps.origin : config.originUrl; // reset visibility once the first render

  useEffect(() => {
    loadCanny();
    loadTheme();
  }, []);
  let head = <>
      <Head>
        <link rel="shortcut icon" href="/favicon.svg" />
        <link rel="mask-icon" href="/favicon.svg" color="#000000" />
        <title>Commonstock</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
        <meta name="facebook-domain-verification" content="wejie26seo5lel69kgj4ih092a247g" />

        {
        /* reconnect required origins to improve page performance: https://web.dev/uses-rel-preconnect/?utm_source=lighthouse&utm_medium=devtools */
      }
        <link rel="preconnect" href={`https://api-${config.sendBirdAppId}.sendbird.com`} crossOrigin="true" />
        <link rel="preconnect" href="https://gateway.commonstock.com" crossOrigin="true" />
        <link rel="preconnect" href="https://events.launchdarkly.com" crossOrigin="true" />
        <link rel="preconnect" href="https://api-js.mixpanel.com" crossOrigin="true" />
        <link rel="preconnect" href="https://cdn-assets.commonstock.com" crossOrigin="true" />
        <link rel="preconnect" href="https://clientstream.launchdarkly.com" crossOrigin="true" />

        {typeof window !== 'undefined' && !localStorage.getItem(AuthTokensKey) && config.CS_ENV === Env.Prod && // @NOTE GA snipped in google-analytics.js
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.publicGATrackingId}`} />}
      </Head>

      <SeoTags {...getBaseSeoProps(origin, router.asPath)} />
    </>;
  return <ErrorBoundary>
      {head}
      <ScreenAnalytics Component={Component} router={router}>
        {getLayout ? getLayout(<Component {...pageProps} origin={origin} />) : <Component {...pageProps} origin={origin} />}
      </ScreenAnalytics>
    </ErrorBoundary>;
}; // attempt to decode jwt sync such that we can initialize launch darkly immediately


let tokenData: JWTValues | null = null;

try {
  let tokensString = typeof window !== 'undefined' && localStorage.getItem(AuthTokensKey);
  let tokens = tokensString ? JSON.parse(tokensString) : null;
  tokenData = tokens && jwt_decode(tokens.access);
} catch (err) {
  captureException(err, {
    context: 'JWT token decode in _app'
  });
}

if (tokenData?.uuid) identifyAnalytics(tokenData?.uuid);else if (!isServer) setAnalyticsLoggedOut(true); // set the provisional user using token data. The full user will be reconciled in AnalyticsControlleer.

let ldProvisionalUser = tokenData ? {
  key: tokenData?.uuid,
  email: tokenData?.email
} : ldAnonUser;
export default withLDProvider({
  clientSideID: config.launchDarklyClientId,
  user: ldProvisionalUser,
  options: {
    bootstrap: 'localStorage'
  }
})(MyApp); // linaria version (noop in development)

export const globals = "gpg0y66";

require("../.linaria-cache/packages/oxcart/pages/_app.linaria.css");