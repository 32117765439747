import React from 'react'
import {
  ArticleJsonLd,
  ArticleJsonLdProps,
  BreadcrumbJsonLd,
  BreadCrumbJsonLdProps,
  DefaultSeo,
  DefaultSeoProps,
  LogoJsonLd,
  LogoJsonLdProps,
  SocialProfileJsonLd,
  SocialProfileJsonLdProps
} from 'next-seo'
import config from 'src/config'

export type SeoTagsProps = {
  seoProps: DefaultSeoProps
  breadcrumb?: BreadCrumbJsonLdProps
  article?: ArticleJsonLdProps
  logo?: LogoJsonLdProps
  social?: SocialProfileJsonLdProps
}
function SeoTags({ seoProps, ...rest }: SeoTagsProps) {
  return (
    <>
      <DefaultSeo {...seoProps} />
      {rest.logo ? <LogoJsonLd {...rest.logo} /> : null}
      {rest.social ? <SocialProfileJsonLd {...rest.social} /> : null}
      {rest.breadcrumb ? <BreadcrumbJsonLd {...rest.breadcrumb} /> : null}
      {rest.article ? <ArticleJsonLd {...rest.article} /> : null}
    </>
  )
}
export default SeoTags

const DEFAULT_TITLE = 'Verified Investing Knowledge | Commonstock'
const DEFAULT_DESCRIPTION =
  'Commonstock is a social network that amplifies the knowledge of the best investors, verified by actual track records for signal over noise.'

export function getBaseSeoProps(origin: string, path: string): SeoTagsProps {
  origin = origin || config.originUrl
  // Remove Query string and hashs
  const pathWithoutQuery = path.split('?')[0].split('#')[0]
  // ignore query for this, as in all current cases query doees not affect the url we want google to index
  const canonicalURL = origin + pathWithoutQuery

  return {
    seoProps: {
      defaultTitle: DEFAULT_TITLE,
      description: DEFAULT_DESCRIPTION,
      canonical: canonicalURL,
      openGraph: {
        url: canonicalURL,
        title: DEFAULT_TITLE,
        type: 'website',
        description: DEFAULT_DESCRIPTION,
        site_name: 'Commonstock',
        locale: 'en_US',
        images: [
          {
            url: `${origin}/images/Frame-2335.jpg`,
            alt: DEFAULT_TITLE,
            height: 1200,
            width: 630
          }
        ]
      },
      twitter: {
        handle: '@JoinCommonstock',
        site: '@JoinCommonstock',
        cardType: 'summary_large_image'
      },
      facebook: {
        appId: '376652679391634'
      }
    }
  }
}
