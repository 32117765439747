export const ldAnonUser = {
  // all users need to same key to avoid creating a bunch of MAU https://docs.launchdarkly.com/home/users/anonymous-users#understanding-how-anonymous-users-contribute-to-mau
  key: '0',
  name: 'Anon',
  anonymous: true
}

export type AnalyticsNodeValue = {
  // screen
  screen?: Screen
  screen_path?: string
  screen_shallow_route?: boolean

  // content
  content_type?: ContentType
  content_id?: string
  content_presentation?: Presentation
  content_author?: string

  // subcontent (e.g. comment)
  subcontent_type?: string // @todo enum
  subcontent_id?: string
}

export enum Screen {
  Unknown = 'Unknown',

  Home = 'Home',
  FollowingFeed = 'Following Feed',
  Trending = 'Trending',
  Leaderboard = 'Leaderboard',
  Portfolio = 'Portfolio',
  Discover = 'Discover',
  Notifications = 'Notifications',
  Search = 'Search',
  Bookmark = 'Bookmark',

  Profile = 'Profile',
  ProfilePosts = 'Profile Posts',
  ProfileTrades = 'Profile Trades',
  ProfileComments = 'Profile Comments',
  ProfileWatchlist = 'Profile Watchlist',
  ProfileFollowers = 'Profile Followers',
  ProfileFollowing = 'Profile Following',
  PendingRequests = 'Pending Requests',

  Asset = 'Asset',
  AssetAbout = 'Asset About',
  AssetTrades = 'Asset Trades',
  AssetTradesList = 'Asset TradesList',
  AssetRetail = 'Asset Retail',

  Tag = 'Tag',
  TextEditor = 'Text Editor',
  TradeDetail = 'Trade Detail',
  PostDetail = 'Memo Detail',
  EditPost = 'Edit Post',

  Login = 'Login',
  Signup = 'Signup',
  PasswordLogin = 'PasswordLogin',
  ForgotPassword = 'ForgotPassword',

  OnboardProfile = 'Onboard Profile',
  OnboardTwitter = 'Onboard Twitter',
  OnboardTwitterFollow = 'Onboard Twitter Follow',
  OnboardTopInvestors = 'Onboard Top Investors',
  OnboardLinkBroker = 'Onboard Link Broker',
  OnboardChooseBroker = 'Onboard Choose Broker',
  OnboardLinkBrokerComplete = 'Onboard Link Broker Complete',
  OnboardTags = 'Onboard Tags',

  Account = 'Account',
  PrivacyAndSecurity = 'Privacy And Security',
  BlockedAccounts = 'Blocked Accounts',
  LinkedAccount = 'Linked Account',
  LinkedAccounts = 'Linked Accounts',
  BrokerLinkComplete = 'Link Broker Complete',

  Chat = 'Chat',
  ChatChannel = 'Chat Channel',
  TwitterFollow = 'Twitter Follow',
  FollowUsers = 'FollowUsers',

  TradeComplete = 'Trade Complete',
  TradeIntent = 'Trade Intent',
  TradePreview = 'Trade Preview'
}

export enum Presentation {
  Screen = 'screen',
  Inline = 'inline'
}

export enum ContentType {
  Post = 'Post',
  Trade = 'Trade',
  Profile = 'Profile'
}
