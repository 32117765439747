import { Router } from 'next/router'
import { ReactNode, useEffect } from 'react'
import config, { Env } from 'src/config'
import { OxcartPage } from 'src/utils/oxcart'
import { AnalyticsNode } from './AnalyticsNode'
import { Screen } from './constants'

function ScreenAnalytics({
  children,
  Component,
  router
}: {
  children?: ReactNode
  Component: OxcartPage<unknown>
  router: Router
}) {
  let screen = Component.screen || Screen.Unknown

  useEffect(() => {
    if (screen === Screen.Unknown && config.CS_ENV !== Env.Prod)
      console.warn('This page has no screen prop defined, it may be important for analytics')
  }, [screen])

  // @TODO enable once metrics v3 is ready to implement
  // let trackScreenView = useTrackScreenView()
  // useEffect(() => {
  //   if (!router.isReady) return
  //   trackScreenView({
  //     screen,
  //     screen_path: router.asPath,
  //     screen_shallow_route: !!router._shallow
  //   })
  // }, [screen, router.asPath, router._shallow, router.isReady, trackScreenView])

  return (
    <AnalyticsNode screen={screen} screen_path={router.asPath} screen_shallow_route={!!router._shallow}>
      {children}
    </AnalyticsNode>
  )
}

export default ScreenAnalytics
