export enum Apis {
  Auth = 'Auth',
  Gateway = 'Gateway',
  Media = 'Media',
  NotificationWS = 'NotificationWS',
  OGImageGenerator = 'OGImageGenerator',
  // non env specific
  MockApi = 'MockApi',
  Auth0 = 'Auth0',
  Giphy = 'Giphy'
}
